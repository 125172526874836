<template>
  <button @click="onClick" class="button list-button"><img class="icon" :src="imgSrc" :title="label"></button>
</template>
<script>
export default {
  props: {
    imgSrc: {
      type: String,
      required: true
    },
    label: {
      type: String
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>
<style>
.list-button {
  width: 40px;
  vertical-align: middle;
  opacity: 0.85;
  margin: var(--spacing-xs);
}

.list-button:hover {
  opacity: 1;
}
</style>
