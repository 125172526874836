<template>
  <section :class="classes">
    <h3 v-if="title">{{ title }}</h3>
    <ul>
      <li class="header" v-if="hasHeader">
        <slot name="header" />
      </li>
      <slot />
      <li class="footer" v-if="hasFooter">
        <slot name="footer" />
      </li>
    </ul>
  </section>
</template>

<script>

export default {
  props: {
    title: {
      type: String
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      return {
        'detail-list': true,
        'wide': this.wide
      }
    },
    hasHeader () {
      return !!this.$slots.header
    },
    hasFooter () {
      return !!this.$slots.footer
    }
  }
}
</script>

<style>
@media screen and (min-width: 52.9rem) {
  .detail-list.wide {
    grid-column: 1 / 3;
  }
}

.detail-list h3 {
  margin-top: var(--spacing-s);
}

.detail-list > ul > li {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
  column-gap: var(--box-padding-h);
  align-items: center;
}

.detail-list > ul > li.header {
  font-size: var(--font-xs);
  font-weight: 600;
  color: var(--secondary-highlight-color);
}

.detail-list > ul > li.footer {
  font-weight: 400;
  color: var(--secondary-highlight-color);
}

.detail-list > ul {
  border: var(--box-border);
  border-radius: var(--box-radius);
}

.detail-list > ul > li:not(.detail-list-item) {
  padding: var(--box-padding);
}

.detail-list > ul > li ~ li {
  border-top: var(--box-border);
}
</style>
