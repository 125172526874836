<i18n>
{
  "de": {
    "portfolioLink": "Liegenschaften",
    "reportLink": "Absenkpfad",
    "scenarioLink": "Szenarien",
    "settingsLink": "Einstellungen",
    "accountLink": "Account",
    "loading": "Daten werden geladen..."
  }
}
</i18n>

<template>
  <div>
    <header class="compass-header">
      <nav class="primary-nav">
        <router-link id="header-logo" :to="{ name: 'home' }"><img src="/app_logo.svg"></router-link>
        <portfolio-picker v-if="loggedIn" :selected-portfolio="portfolio" />
        <account-link />
      </nav>
      <nav class="secondary-nav" v-if="loggedIn && portfolio">
        <div class="secondary-nav-items">
          <router-link :to="{ name: 'portfolio', params: { portfolio_id: portfolio.id } }">{{ $t('portfolioLink') }}</router-link>
          <router-link :to="{ name: 'report', params: { portfolio_id: portfolio.id } }">{{ $t('reportLink') }}</router-link>
          <router-link :to="{ name: 'scenarios', params: { portfolio_id: portfolio.id } }">{{ $t('scenarioLink') }}</router-link>
          <router-link :to="{ name: 'settings', params: { portfolio_id: portfolio.id } }" v-if="portfolio.permissions && portfolio.permissions.EDIT_PARAMS">{{ $t('settingsLink') }}</router-link>
        </div>
      </nav>
    </header>
    <div class="compass-contents">
      <div v-if="!ready">
        {{ $t('loading') }}
      </div>
      <div v-else>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import AccountLink from '@/components/shared/AccountLink'
import PortfolioPicker from '@/components/shared/PortfolioPicker'

import { mapGetters } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object
    },
    ready: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'account/loggedIn'
    })
  },
  components: {
    AccountLink,
    PortfolioPicker
  }
}
</script>

<style>
.compass-contents {
  margin: var(--spacing-m);
}

.compass-header {
  box-shadow: 0px 0px 4px 1px var(--shadow-color);
  height: var(--header-height);
}

.compass-header a {
  font-size: var(--font-s);
  font-weight: 400;
}

.compass-header #header-logo {
  display:inherit;
}

.compass-header nav {
  display: grid;
  grid-gap: var(--spacing-m);
  align-items: center;
  padding: var(--spacing-s) var(--spacing-m);
}

.compass-header .primary-nav {
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-items: start;
  border-bottom: var(--hairline-width) solid var(--hairline-color);
  padding: var(--spacing-m) var(--spacing-m);
  align-items: center;
}

.compass-header .secondary-nav {
  grid-template-columns: auto 1fr;
  justify-items: end;
  height: var(--sticky-header-height);
}

.compass-header .secondary-nav .secondary-nav-items a {
  margin-right: var(--spacing-m);
}

.compass-header .secondary-nav a.router-link-active {
  /* text-decoration: underline; */
  color: rgba(0,0,0,1.0);
  border-bottom: 2px solid #000;
}
</style>
