<!--
TODO: needs refactoring. replace by v-select to enable searching?
-->
<template>
  <section class="dropdown-menu">
    <a href="#" ref="title" @click.prevent="toggleDropdown"><div class="dropdown-menu-title"><slot name="title" /></div><img class="dropdown-menu-icon" src="/icons/chevron-down.svg"></a>
    <ul ref="contents" :class="dropdownClasses">
      <slot name="contents" />
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    stayOpen: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: false
    }
  },
  computed: {
    dropdownClasses () {
      return {
        'dropdown-menu-content': true,
        'dropdown-menu-left': this.left,
        'dropdown-menu-open': this.state
      }
    }
  },
  methods: {
    toggleDropdown (e) {
      this.state = !this.state
    },
    close (e) {
      if (!this.$refs.title.contains(e.target) && (!this.stayOpen || !this.$refs.contents.contains(e.target))) {
        this.state = false
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
  }
}
</script>

<style>
.dropdown-menu {
  position: relative;
}

.dropdown-menu .dropdown-menu-icon {
  display: inline-block;
  vertical-align: top;
  opacity: 0.3;
}

.dropdown-menu .dropdown-menu-title {
  display: inline-block;
  vertical-align: top;
}

.dropdown-menu > a {
  display:block;
}

.dropdown-menu > a:hover .dropdown-menu-icon {
  opacity: 1.0;
}

.dropdown-menu .dropdown-menu-content {
  z-index: 5;
  background-color: var(--background-color);
  position: absolute;
  top: var(--spacing-l);
  right: 0;
  min-width: 12rem;
  border: var(--box-border);
  border-radius: var(--box-radius);
  display: none;
  text-align: left;
  box-shadow: 4px 4px 5px 1px var(--shadow-color);
}

.dropdown-menu .dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu .dropdown-menu-open {
  display: block;
}

.dropdown-menu .dropdown-menu-content > li > a {
  display: block;
  white-space: nowrap;
  padding: var(--box-padding);
}

.dropdown-menu .dropdown-menu-content > li ~ li {
  border-top: var(--box-border);
}
</style>
