<i18n>
{
  "de": {
    "adminUsersLink": "User Admin",
    "adminPortfoliosLink": "Portfolio Admin",
    "userManagement": "Userverwaltung (Admin)",
    "login": "Anmelden",
    "logout": "Abmelden"
  }
}
</i18n>

<template>
  <dropdown-menu v-if="loggedIn" class="account-link">
    <template v-slot:title>
      {{ username }}
    </template>
    <template #contents>
      <li><router-link :to="{ name: 'home' }">{{ username }}</router-link></li>
      <template v-if="isAdmin">
        <li><router-link :to="{ name: 'adminUsers' }">{{ $t('adminUsersLink') }}</router-link></li>
        <li><router-link :to="{ name: 'adminPortfolios' }">{{ $t('adminPortfoliosLink') }}</router-link></li>
      </template>
      <li><router-link :to="{ name: 'login' }">{{ $t('logout') }}</router-link></li>
    </template>
  </dropdown-menu>
  <router-link v-else class="account-link" :to="{ name: 'login' }">{{ $t('login') }}</router-link>
</template>

<script>
import DropdownMenu from './DropdownMenu'

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      username: 'account/name',
      loggedIn: 'account/loggedIn',
      isAdmin: 'account/admin'
    })
  },
  components: {
    DropdownMenu
  }
}
</script>

<style>

</style>
