<i18n>
{
  "de": {
    "selectPortfolio": "Portfolio auswählen",
    "loading": "Daten werden geladen"
  }
}
</i18n>

<template>
  <dropdown-menu class="portfolio-picker" v-if="portfolios && portfolios.length > 0">
    <template v-slot:title>
      {{ selectionTitle }}
    </template>
    <template #contents>
      <li v-for="portfolio in portfolios" :key="portfolio.id"><router-link :to="{ name: 'portfolio', params: { portfolio_id: portfolio.id } }">{{ portfolio.name }}</router-link></li>
    </template>
  </dropdown-menu>
  <span class="portfolio-picker" v-else>{{ $t('loading') }}</span>
</template>

<script>
import DropdownMenu from './DropdownMenu'

import { mapGetters } from 'vuex'

export default {
  props: {
    selectedPortfolio: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      portfolios: 'portfolio/getAll'
    }),
    selectionTitle () {
      return this.selectedPortfolio ? this.selectedPortfolio.name : this.$t('selectPortfolio')
    }
  },
  components: {
    DropdownMenu
  }
}
</script>

<style>
.portfolio-picker a.dropdown-menu-title {
  font-size: var(--font-s);
}
</style>
