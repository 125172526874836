<i18n>
{
  "de": {
    "listTitles": {
      "id": "ID",
      "name": "Name",
      "email": "E-Mail",
      "company": "Firma"
    },
    "users": "Registrierte Benutzer*innen",
    "noUsers": "Keine Benutzer*innen",
    "addUser": "Neue*n Benutzer*in erstellen",
    "deleteConfirmation": "Wollen Sie Benutzer*in {first_name} {last_name} ({email}) wirklich löschen?",
    "toggleAdminOffButtonLabel": "Admin-Rechte entziehen",
    "toggleAdminOffConfirmation": "Wollen Sie Benutzer*in {name} wirklich die Admin-Rechte entziehen?",
    "toggleAdminOnButtonLabel": "Admin-Rechte geben",
    "toggleAdminOnConfirmation": "Wollen Sie Benutzer*in {name} wirklich Admin-Rechte geben?"
  }
}
</i18n>

<template>
  <main-layout :ready="allUsersReady" class="admin-users-page">
    <template #default>
      <h1>{{ $t('users') }}</h1>
      <button-wrapper>
        <router-link class="button" :to="{ name: 'adminAddUser' }">{{ $t('addUser') }}</router-link>
      </button-wrapper>

      <detail-list class="users-list" v-if="allUsers.length > 0">
        <template #header>
          <span>{{ $t('listTitles.id') }}</span>
          <span>{{ $t('listTitles.name') }}</span>
          <span>{{ $t('listTitles.company') }}</span>
          <span>{{ $t('listTitles.email') }}</span>
          <span class="align-right"></span>
          <span class="align-right"></span>
        </template>
        <li class="user-list-row" v-for="user in allUsers" :key="user.id">
          <router-link :to="{ name: 'adminUser', params: { user_id: user.id }}">
            <span>{{ user.id }}</span>
            <span><strong>{{ user.first_name }} {{ user.last_name }}</strong></span>
            <span>{{ user.company_name }}</span>
            <span>{{ user.email }}</span>
            <div class="align-right"><span v-if="user.admin" class="admin-badge">Admin</span></div>
          </router-link>
          <list-button-wrapper>
            <div v-if="loggedInUserEmail !== user.email">
              <list-delete-button @click="onDelete(user)" />
              <button v-if="user.admin" class="button" type="submit" @click.prevent="onToggleAdminOff(user)">{{ $t('toggleAdminOffButtonLabel') }}</button>
              <button v-if="!user.admin" class="button" type="submit" @click.prevent="onToggleAdminOn(user)">{{ $t('toggleAdminOnButtonLabel') }}</button>
            </div>
          </list-button-wrapper>
        </li>
      </detail-list>

      <p v-else>{{ $t('noUsers') }}</p>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AdminAllUsersMixin from '@/pages/vuex-mixins/AdminAllUsersMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import DetailList from '@/components/shared/lists/DetailList'

import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [
    AdminAllUsersMixin // Provides: allUsers, allUsersReady
  ],
  computed: {
    ...mapGetters({
      loggedInUserEmail: 'account/email'
    }),
    hasAdmins () {
      return Boolean((this.allUsers || []).find(u => u.admin))
    }
  },
  methods: {
    ...mapActions({
      deleteUser: 'users/deleteUser',
      addAdminRoleToUser: 'users/addAdminRoleToUser',
      removeAdminRoleFromUser: 'users/removeAdminRoleFromUser'
    }),
    async onDelete (user) {
      const confirmationText = this.$t('deleteConfirmation', { ...user })
      if (confirm(confirmationText)) {
        try {
          await this.deleteUser(user.id)
        } catch (error) {
          this.error = error
        }
      }
    },
    async onToggleAdminOff (user) {
      const confirmationText = this.$t('toggleAdminOffConfirmation', { ...user })
      if (confirm(confirmationText)) {
        try {
          await this.removeAdminRoleFromUser(user.id)
        } catch (error) {
          this.error = error
        }
      }
    },
    async onToggleAdminOn (user) {
      const confirmationText = this.$t('toggleAdminOnConfirmation', { ...name })
      if (confirm(confirmationText)) {
        try {
          await this.addAdminRoleToUser(user.id)
        } catch (error) {
          this.error = error
        }
      }
    }
  },
  components: {
    ListButtonWrapper,
    ListDeleteButton,
    MainLayout,
    ButtonWrapper,
    DetailList
  }
}
</script>

<style>
.admin-users-page .detail-list.users-list > ul > li {
  grid-template-columns: 60px 1fr 1fr 1fr 100px 240px;
}
.admin-users-page .user-list-row > a {
  display: contents;
}

.admin-users-page .user-list-row .admin-badge {
  background-color: var(--secondary-highlight-color);
  color: var(--inverted-text-color);
  padding: 0 var(--spacing-xs);
  border-radius: var(--box-radius);
  text-transform: uppercase;
  font-weight: 400;
}

</style>
